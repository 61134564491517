<template>
    <div class="img-list-main">
        <div v-for="(item,index) in listData" :key="index">
            <!--<router-link :to="'/index/cardDetail?id='+item.id">-->
            <router-link :to="{path:'/index/cardDetail',query:{id:item.id}}">
                <div class="img-item">
                    <el-image
                            :src="item.card_versions[0].image_url"
                            fit="fill"
                            @error="imgLoadErr(index)"
                    >
                    </el-image>
                </div>
            </router-link>
        </div>
        <div class="m-clear-both"></div>
    </div>
</template>

<script>
    var defaultUrl = "https://oss-bushiroad-vg.oss-cn-beijing.aliyuncs.com/card_versions/dbt05_162.jpg";
    export default {
        name: "img-list",
        props: {
            listData: {
                type: Array,
                default: function () {
                    return [];
                }
            },
        },
        methods: {
            imgLoadErr(index) {
                this.listData[index]["card_versions"][0].image_url = defaultUrl
            }
        }
    }
</script>

<style scoped>
    .img-list-main{

    }
    .img-item{
        width: 48%;
        float: left;
        padding: 1%;
    }
    .img-item > img{
        width: 100%;
    }
</style>
