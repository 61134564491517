<template>
    <div class="text-list-main">
        <div class="list-item m-a-none" v-for="(item,index) in listData" :key="index">
            <router-link :to="'/index/cardDetail?id='+item.id">
                <div class="list-item-img">
                    <el-image
                            :src="item.card_versions[0].image_url"
                            fit="fill"
                            style="width: 110px;max-height: 160px"
                    ></el-image>
                </div>
                <div class="list-item-text">
                    <br>
                    <span>{{item.card_versions[0].number}}</span>
                    <el-divider/>
                    <h4>{{item.name_cn}}</h4>
                    <el-divider/>
                    <div class="list-item-text-desc">
                        {{item.unit_type}}&nbsp;|
                        {{item.nation}}&nbsp;|
                        {{item.grade}}&nbsp;级&nbsp;|
                        力量:{{item.power}}&nbsp;|
                        护盾:{{item.shield}}
                    </div>
                    <el-divider/>
                    <div class="card_ability" v-html="formatAbility(item.ability)"></div>
                </div>
                <div class="m-clear-both"></div>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "text-list",
        props: {
            listData: {
                type: [],
                default: function () {
                    return [];
                }
            },
        },
        methods: {
            formatAbility(str) {
                if (!str){
                    return "";
                }
                str = str.replaceAll("【自】","<br>【自】");
                str = str.replaceAll("【起】","<br>【起】");
                str = str.replaceAll("【永】","<br>【永】");
                if (str.indexOf("<br>") == 0) {
                    str = str.replace("<br>","");
                }
                return str;
            }
        },
    }
</script>

<style scoped>
    .text-list-main{
        width: 96%;
        border: 2px solid #00a5e8;
        margin: 0 auto;
    }
    .list-item:hover{
        background-color: #d9efff;
    }
    .list-item{
        border-bottom: 1px solid #00a5e8;
        height: auto;
        width: 100%;
    }
    .list-item-img{
        width: 30%;
        height: 170px;
        padding: 25px;
        margin: 0 auto;
    }
    .list-item-text{
        width: 100%;
        color: #333333;
        /* 将对象作为弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 设置或检索伸缩盒对象的子元素的排列方式 。 */
        -webkit-box-orient: vertical;
        /*  用来限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 10;
        overflow: hidden;
    }
    .list-item-text-desc{
        line-height: 16px;
        font-weight: bold;
    }
    .el-divider--horizontal{
        margin: 5px 0;
    }
    .card_ability{
        font-size: 16px;
        margin-bottom: 10px;
    }
</style>
